<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Crop Type" vid="type_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="type_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="type_name"
                          v-model="CropType.type_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Crop Type (Bn)" vid="type_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="type_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.cropTypeBn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="type_name_bn"
                          v-model="CropType.type_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                     <ValidationProvider name="Category" vid='category_id'>
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="category_id"
                            slot-scope="{ valid, errors }"
                            >
                            <b-form-radio-group
                                v-model="CropType.category_id"
                                :options="options"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-radio-group>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { cropTypeStore, cropTypeUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getCropTypeData()
      this.CropType = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      CropType: {
        id: '',
        type_name: '',
        type_name_bn: '',
        category_id: 1,
        status: '1'
      },
      options: [
          { item: 1, name: this.$t('seedsConfig.hybrid') },
          { item: 2, name: this.$t('seedsConfig.inbrid') }
        ]
    }
  },
  methods: {
    getCropTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.CropType.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${cropTypeUpdate}/${this.id}`, this.CropType)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, cropTypeStore, this.CropType)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
